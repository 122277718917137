import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "The effect of CSS on screen readers",
  "description": "We can find a lot of information about how badly written HTML can affect the screen readers, but there is almost nothing about CSS.",
  "lead": "We can find a lot of information about how badly written HTML can affect the screen readers, but there is almost nothing about CSS.",
  "dateCreated": "2021-09-02T00:00:00.000Z",
  "tags": "CSS",
  "slug": "the-effect-of-css-on-screen-readers",
  "postUrl": "https://uselessdivs.com/blog/the-effect-of-css-on-screen-readers/",
  "ogImg": "https://uselessdivs.com/img/the-effect-of-css-on-screen-readers/og-img.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A few weeks ago I noticed that NVDA read my `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<ul>`}</code>{` wrong. It forgot to say "bullet" before the list items. I scratched my head for a few minutes, then I realized, that `}<strong parentName="p">{`CSS changed the behaviour of my semantically correct HTML`}</strong>{`.`}</p>
    <p>{`After this "incident", I searched for what other effects CSS has on screen readers and I found a brilliant article about this topic from Ben Myers. (You can find the link at the end of this article.)`}</p>
    <p>{`This article was 1.5 years old, when I found it, so I decided to redo the tests to see, if anything has changed and added some new elements to my list.
I will do tests with `}<strong parentName="p">{`VoiceOver`}</strong>{` and `}<strong parentName="p">{`NVDA`}</strong>{` in `}<strong parentName="p">{`Google Chrome`}</strong>{` and `}<strong parentName="p">{`Mozilla Firefox`}</strong>{`.`}</p>
    <h2>{`CSS vs. unordered lists `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`<ul>`}</code></h2>
    <p>{`This is what I noticed myself. I used `}<strong parentName="p">{`list-style-type: none`}</strong>{` on an unordered list to get rid of the default bullets before the list items.`}</p>
    <p>{`Basic behaviour: It tells me, it is a list and how many items are in the list. And say "bullet" before every list items.`}</p>
    <p>{`Experienced behaviour: It tells me, it is a list and how many items are in the list, but `}<strong parentName="p">{`forget to say "bullet"`}</strong>{` before list items.`}</p>
    <p>{`There wasn't any difference between the two screen readers and the two browsers.`}</p>
    <p>{`I didn't tested it in Safari, but Ben Myers wrote, that VoiceOver in Safari doesn't treated it as a list at all.`}</p>
    <iframe height="300" style={{
      "width": "100%"
    }} scrolling="no" title="" src="https://codepen.io/polgarj/embed/xxrOqEV?default-tab=html%2Cresult" frameBorder="no" loading="lazy" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href="https://codepen.io/polgarj/pen/xxrOqEV">
  </a> by Jozsef Polgar (<a href="https://codepen.io/polgarj">@polgarj</a>)
  on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <h2>{`CSS vs. ordered lists `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`<ol>`}</code></h2>
    <p>{`I did the same test with ordered list, and I experienced almost the same.`}</p>
    <p>{`Basic behaviour: It tells me, it is a list and how many items are in the list. And say this is the first/second/third/etc. item before every `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<li>`}</code>{`.`}</p>
    <p>{`Experienced behaviour: It tells me, it is a list and how many items are in the list, but `}<strong parentName="p">{`forget to say the position of the item in the list`}</strong>{`.`}</p>
    <iframe height="300" style={{
      "width": "100%"
    }} scrolling="no" title="" src="https://codepen.io/polgarj/embed/Pojzmqg?default-tab=html%2Cresult" frameBorder="no" loading="lazy" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href="https://codepen.io/polgarj/pen/Pojzmqg">
  </a> by Jozsef Polgar (<a href="https://codepen.io/polgarj">@polgarj</a>)
  on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <h2>{`CSS vs. text-transform`}</h2>
    <p>{`Ben Myers mentioned, that VoiceOver messed up the text on a button, because it was changed to uppercase with the `}<strong parentName="p">{`text-transform`}</strong>{` property.
The text on the button was "Add", and VoiceOver read it as the acronym A.D.D.`}</p>
    <p>{`Luckily `}<em parentName="p">{`I didn't noticed it, so I think it is fixed now.`}</em></p>
    <h2>{`CSS vs. flexbox ordering`}</h2>
    <p>{`If you change the original order of the HTML elements with flexbox ordering it will not affect the screen readers at all. It will read the content as you wrote it in your HTML.`}</p>
    <iframe height="300" style={{
      "width": "100%"
    }} scrolling="no" title="" src="https://codepen.io/polgarj/embed/powbPOP?default-tab=html%2Cresult" frameBorder="no" loading="lazy" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href="https://codepen.io/polgarj/pen/powbPOP">
  </a> by Jozsef Polgar (<a href="https://codepen.io/polgarj">@polgarj</a>)
  on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <h2>{`CSS vs. CSS generated elements`}</h2>
    <p>{`Screen readers will handle `}<strong parentName="p">{`::before`}</strong>{` and `}<strong parentName="p">{`::after`}</strong>{` pseudo-elements as content.
You can also use `}<strong parentName="p">{`::marker`}</strong>{` pseudo-element before list items to create custom indicators like bullet points, numbers, letters, etc.`}</p>
    <p>{`Before you use them to add content to your website, it is good to know, that `}<strong parentName="p">{`they are not rendered in the DOM`}</strong>{`.
In some special cases (for example, if CSS is turned off), visitors may miss these elements.`}</p>
    <h2>{`CSS vs. tables`}</h2>
    <p>{`Maybe it is obvious but if you give any display property for a table other than `}<strong parentName="p">{`display: table`}</strong>{` it will totally mess up it's semantics. So `}<em parentName="p">{`please don't add display: grid to a table.`}</em>{` It's better to look for another layout.`}</p>
    <h2>{`CSS vs. display and visibility`}</h2>
    <p>{`If you want to hide something from everyone, you should use `}<strong parentName="p">{`display: none;`}</strong>{` or `}<strong parentName="p">{`visibility: hidden;`}</strong>{` on the element.`}</p>
    <p>{`If you want to hide some content visually, but present for screen readers, you should use something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.sr-only`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`rect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`white-space`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`nowrap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-clip-path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`inset`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`50%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip-path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`inset`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`50%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`0
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Just copy and paste it in your CSS file and add the `}<em parentName="p">{`.sr-only`}</em>{` class to the element, you want to hide.`}</p>
    <p>{`I have to mention 2 HTML related things here:`}</p>
    <ul>
      <li parentName="ul">{`you can hide content from everybody by adding the `}<strong parentName="li">{`hidden`}</strong>{` attribute to the element.`}</li>
      <li parentName="ul">{`and element can be visible, but hidden from screen readers by adding `}<strong parentName="li">{`aria-hidden="true"`}</strong>{` to the element.`}</li>
    </ul>
    <h2>{`Takeaway`}</h2>
    <p>{`Perharps these aren’t such big things, but it’s worth paying attention to little details.
If you find anything that is changed, or you know something I should add to this list, please contact me.
`}<strong parentName="p">{`Keep this article updated together!`}</strong></p>
    <p className="takeaway">
  Always do accessibility test with screen readers, even if it seems fine, because... you never know. :)
    </p>
    <p>{`Here is a very well written article about this topic:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://benmyers.dev/blog/css-can-influence-screenreaders/" rel="noreferrer" target="_blank">Ben Myers - CSS Can Influence Screenreaders</a>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      